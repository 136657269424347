import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Tooltip, Dialog } from 'sw-ui';

import DecisionButtons from '../../../../../components/DecisionButtons';
import Suggest from '../../../../../components/Suggest';
import NoResult from '../../../../../components/NoResult';
import AviaContractForm from './aviaCodeForm';

import COMPONENTS from '../../../../../bi/constants/components';

import { selectObj } from '../../../../../bi/utils/air';

import { ERROR_MESSAGE, FIELDS } from '../../../../../bi/constants/profile';
import { AIRLINE_PROVIDERS } from '../../../../../bi/constants/airline';
import { DECISION_BUTTONS_CAMEL } from '../../../../../bi/constants/decisionButtons';
import { BONUSES_TYPE } from '../../../../../bi/constants/bonuses';

import styles from '../../styles/account.module.scss';

const {
  TOOLTIP: { POSITION: { RIGHT } },
  BUTTON: { THEME: { FLAT } },
} = COMPONENTS;

const SCHEMES_VALUES = {
  DEFAULT: 'DEFAULT',
  SU: 'SU',
};

const SCHEMES = [
  { id: SCHEMES_VALUES.DEFAULT, name: 'NDC SU' },
  { id: SCHEMES_VALUES.SU, name: 'GDS Mixvel' },
];

const LABELS = {
  ADD_CODE: 'ДОБАВИТЬ КОД',
  AEROFLOT_DIALOG: {
    TITLE: 'Необходимо выбрать схему выписки билетов авиакомпании Аэрофлот для аккаунта:',
    SUGGEST_LABEL: 'Схема выписки',
  },
};

export default class AviaContract extends Component {
  static propTypes = {
    companyService: PropTypes.object.isRequired,
    isSmartAgent: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    const { profile, aviaContractData } = props.companyService.get();
    const accountId = Number(location.pathname.split('/')[2]);

    this.state = {
      aeroflotDeleteDialog: false,
      aeroflotDeleteScheme: SCHEMES_VALUES.DEFAULT,
      currentItem: null,
      accountId,
      profile,
      aviaContractData,
    };
  }

  componentDidMount() {
    this.unsubscribe = this.props.companyService.subscribe(this.updateState);
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  updateState = state => this.setState({
    ...state,
  });

  handleCloseErrorDialog = () => this.props.companyService.closeErrorAviaContractDialog();

  handleAddAviaContract = () => this.props.companyService.addAviaContract();

  handleCloseAviaContract = () => this.props.companyService.closeAviaContract();

  handleSaveContract = () => {
    const { accountId } = this.state;

    this.props.companyService.preSaveAviaContract(accountId);
  }

  handleChangeField = (field, value) => this.props.companyService.changeFieldAviaContractData(`${FIELDS.ACTIVE_AVIA_CONTRACT}.${field}`, value);

  getDiscount = ({ Type, Iata }) => {
    if (Type === BONUSES_TYPE.MILES && Iata !== AIRLINE_PROVIDERS.S7) return 0;

    if (Type === BONUSES_TYPE.MILES && Iata === AIRLINE_PROVIDERS.S7) return 2;

    return 1;
  };

  handleShowAviaContract = (item = null) => {
    const modifiedItem = {
      ...item,
      IsDiscount: this.getDiscount(item),
    };

    this.props.companyService.showAviaContract(modifiedItem);
  }

  handleShowAeroflotDialog = () => this.setState(prev => ({ ...prev, aeroflotDeleteDialog: !prev.aeroflotDeleteDialog }));

  handleDeleteContract = (item) => {
    const { aeroflotDeleteScheme, accountId } = this.state;
    const { companyService: { removeAviaContract } } = this.props;
    const { Iata } = item;

    const isAeroflot = Iata === 0;

    if (isAeroflot) {
      return this.setState({
        aeroflotDeleteDialog: true,
        currentItem: item,
      });
    }

    removeAviaContract({ ...item, SchemeType: aeroflotDeleteScheme, AccountId: accountId });
  }

  renderErrorDialog = (showError, message) => (
    showError &&
    <NoResult
      message={ message }
      onClose={ this.handleCloseErrorDialog }
    />
  );

  renderAeroflotDeleteDialog = () => {
    const { companyService: { removeAviaContract } } = this.props;
    const { aeroflotDeleteDialog, currentItem, aeroflotDeleteScheme, accountId } = this.state;

    if (!aeroflotDeleteDialog) return null;

    return (
      <Dialog showCloseButton onClick={ this.handleShowAeroflotDialog } width='500px'>
        <div className={ styles.dialog_aeroflot }>
          <div>{ LABELS.AEROFLOT_DIALOG.TITLE }</div>
          <div className={ styles.suggest }>
            <Suggest
              withScroll
              title={ LABELS.AEROFLOT_DIALOG.SUGGEST_LABEL }
              suggests={ SCHEMES }
              currentLabel={ SCHEMES[0].name }
              onSelect={ ({ id }) => this.setState({ aeroflotDeleteScheme: id }) }
            />
          </div>
          <div className={ styles.buttons }>
            <DecisionButtons
              loadingIncluded
              onCancel={ this.handleShowAeroflotDialog }
              onSave={ () => removeAviaContract({ ...currentItem, SchemeType: aeroflotDeleteScheme, AccountId: accountId }) }
            />
          </div>
        </div>
      </Dialog>
    );
  }

  renderItem = (item) => {
    const { Number, Iata } = item;
    const { loading } = this.state.aviaContractData;
    const finderIata = selectObj(item).find(({ cost }) => cost === Iata);
    const aviaCompanyName = finderIata ? finderIata.name : Iata;

    return item && (
      <div
        key={ Number }
        className={ `${styles.row} ${styles['button-change']}` }
      >
        <div className={ `${styles.avia_info} ${styles['col-1-3']}` }>
          <div className={ `${styles.avia_info__item} ${styles['col-1-2']}` }>
            <div className={ styles.avia_info__label }> Номер:</div>
            <div>{ Number }</div>
          </div>
          <div className={ `${styles.avia_info__item} ${styles['col-1-2']}` }>
            <div className={ styles.avia_info__label }> Авиакомпания:</div>
            <div>{ aviaCompanyName }</div>
          </div>
        </div>
        <Button
          label={ DECISION_BUTTONS_CAMEL.LABELS.EDIT }
          theme={ FLAT }
          onClick={ () => this.handleShowAviaContract(item) }
          className={ styles.button }
        />
        <Button
          label={ DECISION_BUTTONS_CAMEL.LABELS.DELETE }
          theme={ FLAT }
          onClick={ () => this.handleDeleteContract(item) }
          className={ styles.button }
          loading={ loading }
        />
      </div>
    );
  };

  renderShowAviaCodeForm = () => {
    const { aviaContractData, profile: { aviaContracts } } = this.state;

    return (
      <AviaContractForm
        aviaContractData={ aviaContractData }
        aviaContracts={ aviaContracts }
        onClose={ this.handleCloseAviaContract }
        onSave={ this.handleSaveContract }
        onChange={ this.handleChangeField }
      />
    );
  };

  renderTooltip = () => this.props.isSmartAgent ? (
    <Tooltip
      position={ RIGHT }
    >
      { ERROR_MESSAGE.TOOLTIP_ERROR }
    </Tooltip>
  ) : null;

  render() {
    const { isSmartAgent } = this.props;
    const { aviaContractData: { showAviaContractDialog, showErrorSaveAviaContractDialog, showErrorDeleteAviaContractDialog }, profile: { aviaContracts } } = this.state;
    const aviaCodeFormHtml = showAviaContractDialog && this.renderShowAviaCodeForm();

    return (
      <div className={ styles.hotel_corporate }>
        <div className={ styles.form }>
          <label>Корпоративный код авиакомпании</label>
          { aviaContracts.map(this.renderItem) }
          <div className={ styles.row }>
            <div className='sw-tooltip-wrapper'>
              <Button
                label={ LABELS.ADD_CODE }
                theme={ FLAT }
                onClick={ () => this.handleAddAviaContract() }
                className={ styles.button }
                disabled={ isSmartAgent }
              />
              { this.renderTooltip() }
            </div>
          </div>
        </div>
        { aviaCodeFormHtml }
        { this.renderErrorDialog(showErrorSaveAviaContractDialog, ERROR_MESSAGE.SAVE) }
        { this.renderErrorDialog(showErrorDeleteAviaContractDialog, ERROR_MESSAGE.GET) }
        { this.renderAeroflotDeleteDialog() }
      </div>
    );
  }
}
