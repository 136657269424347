import React, { useContext } from 'react';
import VacationAccountContext from '../context';
import BottomTabsLegacy from './BottomTabsLegacy';

const BottomTabs = () => {
  const {
    appParams,
    accountService,
    companyService,
    notificationsService,
    featureFlagsService,
    tripService,
    hotelsService,
    airService,
    moveTo,
    moveToNewTrip,
    moveToUserCartsAndNote,
  } = useContext(VacationAccountContext);

  return (
    <BottomTabsLegacy
      params={ appParams }
      companyService={ companyService }
      notificationsService={ notificationsService }
      accountService={ accountService }
      tripService={ tripService }
      airService={ airService }
      featureFlagsService={ featureFlagsService }
      hotelsService={ hotelsService }
      toAnotherTab={ moveTo }
      toNewTrip={ moveToNewTrip }
      toUserCartsAndNote={ moveToUserCartsAndNote }
    />
  );
};

export default BottomTabs;
